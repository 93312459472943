import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Amchartmenu } from "../../routers/Dashboard/children/ChartDownload";
import _ from "lodash";
import languageFile from "../../../public/languages/Translation";
import { reviewTranslationMap } from "../../../src/utils/reviewStarTranslation";
const starColor = {
	1: "#FF5722",
	2: "#FF9800",
	3: "#ababab",
	4: "#03A9F4",
	5: "#4CAF50",
};

am4core.useTheme(am4themes_animated);

class CommentWordCountChart extends Component {
	formatData(data) {
		let newFormattedData = [];
		_.map(data, (item) => {
			if (item.starRating != "total") {
				newFormattedData.push({
					name: reviewTranslationMap[item.starRating + " star"],
					color: am4core.color(starColor[item.starRating]),
					key: item.starRating,
					count: item.totalWordCount,
					children: [
						{
							name: reviewTranslationMap[item.starRating + " star"],
							value: item.totalWordCount,
							color: am4core.color(starColor[item.starRating]),
							percentage: _.round(item.countPercentage) + "%",
						},
					],
				});
			}
		});
		let orderedData = newFormattedData.sort((a, b) => b.key - a.key);
		return orderedData;
	}

	componentDidMount() {
		var wordLength = am4core.create("wordcountdiv", am4charts.TreeMap);
		wordLength.hiddenState.properties.opacity = 0; // this makes initial fade in effect

		wordLength.data = this.formatData(this.props.chartData);

		wordLength.colors.step = 2;

		// define data fields
		wordLength.dataFields.value = "value";
		wordLength.dataFields.name = "name";
		wordLength.dataFields.children = "children";
		wordLength.dataFields.color = "color";
		wordLength.dataFields.percentage = "percentage";

		wordLength.zoomable = false;
		var bgColor = new am4core.InterfaceColorSet().getFor("background");

		// level 0 series template
		var level0SeriesTemplate = wordLength.seriesTemplates.create("0");
		var level0ColumnTemplate = level0SeriesTemplate.columns.template;

		level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
		level0ColumnTemplate.fillOpacity = 0;
		level0ColumnTemplate.strokeWidth = 4;
		level0ColumnTemplate.strokeOpacity = 0;

		// level 1 series template
		var level1SeriesTemplate = wordLength.seriesTemplates.create("1");
		var level1ColumnTemplate = level1SeriesTemplate.columns.template;

		level1SeriesTemplate.tooltip.animationDuration = 0;
		level1SeriesTemplate.strokeOpacity = 1;

		level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
		level1ColumnTemplate.fillOpacity = 1;
		level1ColumnTemplate.strokeWidth = 4;
		level1ColumnTemplate.stroke = bgColor;
		level1ColumnTemplate.tooltipText = `{name} {percentage} {value} ${
			languageFile[localStorage.getItem("language")]["22701"]
		}`;

		var bullet1 = level1SeriesTemplate.bullets.push(
			new am4charts.LabelBullet(),
		);
		bullet1.locationY = 0.5;
		bullet1.locationX = 0.5;
		bullet1.label.text = "{percentage}";
		bullet1.label.fill = am4core.color("#ffffff");

		wordLength.maxLevels = 2;

		//download
		if (this.props.showDownLoad) {
			wordLength.exporting.menu = new am4core.ExportMenu();
			wordLength.exporting.menu.items = _.cloneDeep(Amchartmenu);
			wordLength.exporting.timeoutDelay = 8000;
			wordLength.exporting.dataFields = {
				key: "starRating",
				count: "word count",
			};
			wordLength.exporting.filePrefix = "commentLength";
		}
		wordLength.legend = new am4charts.Legend();
		wordLength.logo.disabled = true;
		this.wordLength = wordLength;
	}
	componentDidUpdate(oldProps) {
		if (oldProps.chartData !== this.props.chartData) {
			this.wordLength.data = this.formatData(this.props.chartData);
		}
	}
	render() {
		return (
			<div
				id="wordcountdiv"
				style={{ width: "100%", height: "100%", marginBottom: 0 }}></div>
		);
	}
}
export default CommentWordCountChart;
